import styled from 'styled-components';

export const ImageStyle = styled.div`
  background: url(${(props) => props.$background}) repeat-x bottom center;
  height: 850px;
  width: 100%;
  border: 0;
  overflow: hidden;
  vertical-align: top;
  margin: 0 0 40px 0;
  @media (max-width: 870px) {
    margin-bottom: 30px;
    background-size: cover;
    max-height: 530px;
  }
`;
export const HeaderH2 = styled.h2`
  text-align: center !important;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #fff;
  span {
    color: #fec119;
  }
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const TitleDiv = styled.div`
  position: absolute;
  text-align: center !important;
  width: 100%;
  top: 280px;
`;
export const Heading = styled.h1`
  text-align: center;
  position: relative;
  font-family: 'MrAlex', sans-serif;
  font-size: 4vw;
  line-height: 4vw;
  color: #fff;
  display: inline;
  code {
    color: #ffc905;
    font-family: 'MrAlex', sans-serif;
    font-size: 4vw;
    line-height: 4vw;
  }
  @media (max-width: 1199px) {
    font-size: 74px;
    line-height: 74px;
    code {
      font-size: 74px;
      line-height: 74px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 991px) {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 30px;
    code {
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 767px) {
    font-size: 45px;
    line-height: 45px;
    code {
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
`;
export const SpanHeading = styled.h1`
  text-align: center;
  position: relative;
  font-family: 'MrAlex', sans-serif;
  font-size: 4vw;
  line-height: 4vw;
  color: #ffc905;
  display: inline;
  code {
    color: #ffc905;
    font-family: 'MrAlex', sans-serif;
    font-size: 4vw;
    line-height: 4vw;
  }
  @media (max-width: 1199px) {
    font-size: 74px;
    line-height: 74px;
    code {
      font-size: 74px;
      line-height: 74px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 991px) {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 30px;
    code {
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 767px) {
    font-size: 45px;
    line-height: 45px;
    code {
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
`;
export const HeadingContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 320px;
  @media (max-width: 870px) {
    top: 170px;
  }
`;
export const FloatImageStyle = styled.img`
  max-width: 500px;
  height: auto;
  @media (max-width: 870px) {
    display: block;
    max-width: 300px;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
`;
export const ImageContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 40px 0px 0px 0px;
  @media (max-width: 870px) {
    padding: 0px 35px 10px 35px;
    text-align: center;
  }
`;
